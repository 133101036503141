<template>
  <b-card>
    <!-- media  "optionsLocal.avatar" :src="require('@/assets/images/avatars/13-small.png')" -->

    <!-- media  "dados.avatar" :src="require('@/assets/images/avatars/13-small.png')" -->
    <!--/ media -->
    <b-card-text style="display: block; text-align: center" />
    <!--/ media -->

    <validation-observer ref="formConta">
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="3" v-if="!acesso.ConfiguracoesSistemaPerfilEditar">
            <ConfiguracaoContaGeralFoto @foto="mudarFoto" />
          </b-col>
          <b-col sm="9">
            <b-row>
              <!-- NOME -->
              <b-col sm="4">
                <b-form-group label="Nome" label-for="account-name">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    v-model="optionsLocal.name"
                    name="name"
                    placeholder="Nome"
                    rules="required"
                  />
                </b-form-group>
              </b-col>

              <!-- EMAIL -->
              <b-col sm="4">
                <b-form-group label="E-mail" label-for="email">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    v-model="optionsLocal.email"
                    name="email"
                    placeholder="Email"
                    rules="required"
                  />
                </b-form-group>
              </b-col>

              <!-- CARGO   -->
              <b-col sm="4">
                <b-form-group label="Cargo" label-for="cargo">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    v-model="optionsLocal.cargo"
                    name="cargo"
                    placeholder="Cargo"
                    rules="required"
                  />
                </b-form-group>
              </b-col>

              <!-- GENERO -->
              <b-col sm="4">
                <b-form-group label="Gênero" label-for="genero">
                  <b-form-select
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    name="genero"
                    v-model="optionsLocal.genero"
                    :options="generoOpcoes"
                    rules="required"
                  />
                </b-form-group>
              </b-col>

              <!-- aniversario -->
              <b-col md="4">
                <b-form-group label-for="dataNascimento" label="Data de Nascimento">
                  <flat-pickr
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    v-model="optionsLocal.dataNascimento"
                    class="form-control"
                    name="dataNascimento"
                    :config="pickrAniversario"
                    placeholder="Data de Nascimento"
                    rules="required"
                  />
                </b-form-group>
              </b-col>

              <!-- telefone -->
              <b-col md="4">
                <b-form-group label-for="telefone" label="Telefone">
                  <cleave
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    id="telefone"
                    v-model="optionsLocal.telefone"
                    class="form-control"
                    :raw="false"
                    :options="clevePhone"
                    placeholder="Telefone"
                    rules="required"
                  />
                </b-form-group>
              </b-col>

              <!-- bio -->
              <b-col cols="12">
                <b-form-group label="Biografia" label-for="bio-area">
                  <b-form-textarea
                    :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                    id="bio-area"
                    v-model="optionsLocal.bio"
                    rows="4"
                    placeholder="Descreva aqui um pouco de você..."
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click.prevent="salvar"
                >
                  Salvar
                </b-button>
                <b-button
                  :disabled="!acesso.ConfiguracoesSistemaPerfilEditar"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="limpar"
                >
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
  // validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'

  // imagem
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref } from '@vue/composition-api'
  import vSelect from 'vue-select'

  // Para datas
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'
  import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

  // Para formatar o numero
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'

  import VueRouter from 'vue-router'

  // Foto
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import ConfiguracaoContaGeralFoto from './geral/ConfiguracaoContaGeralFoto.vue'
  import { useFotoStore } from './geral/conta-foto.store'

  import 'sweetalert2/src/sweetalert2.scss'

  export default {
    components: {
      ConfiguracaoContaGeralFoto,
      Cleave,
      flatPickr,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      VueRouter,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        foto: null,
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        blogFile: null,
        required,
        store: useFotoStore(),
        generoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'M', text: 'Masculino' },
          { value: 'F', text: 'Feminino' },
        ],
        clevePhone: {
          phone: true,
          phoneRegionCode: 'BR',
          delimiter: ' ',
        },
        pickrAniversario: {
          altFormat: 'd/m/Y',
          altInput: true,
          dateFormat: 'Y-M-d',
          locale: Portuguese,
        },

        acesso: {
          ConfiguracoesSistemaPerfilEditar: false,
        },
      }
    },
    mounted() {
      if ((this.userData.role = 'master')) {
        this.acesso.ConfiguracoesSistemaPerfilEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaPerfilEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaPerfilEditar
      }
    },
    methods: {
      mudarFoto(foto) {
        this.foto = foto
      },
      async salvarFoto(foto) {
        const formData = new FormData()
        formData.append('file', foto)

        await useJwt.post(`cadastro/user/${this.generalData.id}/foto-perfil`, formData)
      },
      limpar() {
        this.store.$state.foto = null
        this.store.$state.arquivo = null
        this.optionsLocal = {}
        this.optionsLocal.id = JSON.parse(JSON.stringify(this.generalData.id))
      },
      onRemoved() {
        this.optionsLocal = { ...this.optionsLocal, foto: null }
      },
      salvar(bvModalEvt) {
        const { store } = this
        bvModalEvt.preventDefault()
        this.$refs.formConta.validate().then((success) => {
          if (success) {
            if (this.optionsLocal.id == undefined) {
              this.$swal({
                title: 'Atenção!',
                text: 'É proibido o cadastro de novos usuários nessa página',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              Swal.fire({
                title: 'Carregando...',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onBeforeOpen: () => {
                  Swal.showLoading()
                },
              })
              const aux = {
                ...this.optionsLocal,
              }
              useJwt
                .update('cadastro/user/updateUsuario', this.optionsLocal.id, aux)
                .then(async (response) => {
                  try {
                    await this.salvarFoto(this.foto)
                  } catch (e) {
                    console.error(e)
                  }
                  const { userData } = this.$jwtDecode(localStorage.getItem('userData'))

                  localStorage.setItem('userData', JSON.stringify({ ...userData, ...response.data }))
                  this.$root.$emit('atualizarFotoDropDown', store.$state.foto)

                  Swal.close()
                })
                .catch((error) => {
                  console.error(error)
                })
                .finally(() => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Registro alterado com sucesso!',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            }
          }
        })
      },
    },
    setup() {
      const refInputEl = ref(null)
      const refPreviewEl = ref(null)

      const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
        refPreviewEl.value.src = base64
      })

      return {
        refInputEl,
        refPreviewEl,
        inputImageRenderer,
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
