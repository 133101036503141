






























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import { useFotoStore } from './conta-foto.store'
import { storeToRefs } from 'pinia'

export default defineComponent({
  setup(props, { emit }) {
    // Input
    const upload = ref<HTMLInputElement | null>(null)
    const imagePreview = ref<HTMLDivElement | null>(null)
    const filenameLabel = ref<HTMLSpanElement | null>(null)
    const isEventListenerAdded = ref(false)

    // Foto
    const store = useFotoStore()

    // Usuario
    const user = this.$jwtDecode(localStorage.getItem('userData'))['userData']

    // Clicar no input
    const abrirSelecaoDeImagem = () => {
      upload.value?.click()
    }

    // Trocar a foto
    const mudarPreview = (src: string) => {
      store.foto = src
      // imagePreview.value.innerHTML = `<img src="${src}" class="max-h-48 rounded-lg mx-auto" alt="Image preview" />`
      // imagePreview.value?.classList.remove('border-dashed', 'border-2', 'border-gray-400')
    }
    const trocarFoto = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      store.$state.arquivo = file
      if (file) {
        emit('foto', file)
        const reader = new FileReader()
        reader.onload = (e) => {
          mudarPreview(e.target.result as any)
          if (!isEventListenerAdded.value) {
            imagePreview.value?.addEventListener('click', () => {
              upload.value?.click()
            })

            isEventListenerAdded.value = true
          }
        }
        reader.readAsDataURL(file)
      } else {
        emit('foto', null)
        filenameLabel.value.textContent = ''
        imagePreview.value.innerHTML = `<div class="bg-gray-200 h-48 rounded-lg flex items-center justify-center text-gray-500">No image preview</div>`
        imagePreview.value.classList.add('border-dashed', 'border-2', 'border-gray-400')

        // Remove the event listener when there's no image
        imagePreview.value.removeEventListener('click', () => {
          upload.value.click()
        })

        isEventListenerAdded.value = false
      }
    }

    onMounted(async () => {
      try {
        const response = await useJwt.get(`cadastro/user/${user.id}/foto-perfil`)

        if (response.data.foto) {
          mudarPreview(response.data.foto)
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      abrirSelecaoDeImagem,
      upload,
      imagePreview,
      filenameLabel,
      store,
      isEventListenerAdded,
      trocarFoto,
    }
  },
})
