<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formSenha">
      <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
        <!-- senha antiga -->
        <b-row>
          <b-col md="4">
            <b-form-group label="Digite sua senha atual" label-for="senha-antiga">
              <b-input-group class="input-group-merge">
                <b-form-input
                  :disabled="acesso.ConfiguracoesSistemaPerfilEditar"
                  id="senha-antiga"
                  v-model="dados.password"
                  name="Senha antiga"
                  :type="campoSenhaAntiga"
                  placeholder="Digite sua atual senha"
                  rules="required"
                  required
                />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="campoSenhaAntiga" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- password -->
        <b-row>
          <b-col md="4">
            <b-form-group label="Nova Senha" label-for="reset-password-new">
              <validation-provider #default="{ errors }" name="Senha" vid="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="acesso.ConfiguracoesSistemaPerfilEditar"
                    id="reset-password-new"
                    v-model="senhaNova"
                    :type="campoSenhaNovo"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="Digite sua nova senha"
                  />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="campoSenhaNovo" @click="togglePassword1Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="d-flex flex-column text-xs">
                <small :class="isValidoPoliticaSenha('senhaQuantidadeCaractere') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                  >No mínimo {{ politicaSenhas.senhaQuantidadeCaractere }} caracteres</small
                >
                <small
                  v-if="politicaSenhas.senhaCaractereEspecial"
                  :class="isValidoPoliticaSenha('senhaCaractereEspecial') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                  >Ao menos um caractere especial</small
                >
                <small
                  v-if="politicaSenhas.senhaLetraMaiuscula"
                  :class="isValidoPoliticaSenha('senhaLetraMaiuscula') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                  >Ao menos uma letra maiúscula</small
                >
                <small
                  v-if="politicaSenhas.senhaLetraMinuscula"
                  :class="isValidoPoliticaSenha('senhaLetraMinuscula') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                  >Ao menos uma letra minúscula</small
                >
                <small
                  v-if="politicaSenhas.senhaNumero"
                  :class="isValidoPoliticaSenha('senhaNumero') ? 'tw-text-zinc-700' : 'tw-text-red-500'"
                  >Ao menos um número</small
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <!-- confirm password -->
            <b-form-group label-for="reset-password-confirm" label="Confirme a nova senha">
              <validation-provider #default="{ errors }" name="Confirmar senha" rules="required|confirmed:Password">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="acesso.ConfiguracoesSistemaPerfilEditar"
                    id="reset-password-confirm"
                    v-model="cSenhaNova"
                    :type="cCampoSenhaNovo"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="Confirme sua atual senha"
                  />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="cCampoSenhaNovo" @click="togglePassword2Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit button -->
        <b-button
          :disabled="acesso.ConfiguracoesSistemaPerfilEditar"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          class="mt-1 mr-1"
          @click="salvar"
        >
          Salvar nova senha
        </b-button>
        <b-button
          :disabled="acesso.ConfiguracoesSistemaPerfilEditar"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          @click.prevent="limpar"
          class="mt-1"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        required,
        dados: {},
        senhaNova: '',
        cSenhaNova: '',
        campoSenhaAntiga: 'password',
        campoSenhaNovo: 'password',
        cCampoSenhaNovo: 'password',
        politicaSenhas: {
          senhaQuantidadeCaractere: 6,
          senhaCaractereEspecial: false,
          senhaLetraMaiuscula: false,
          senhaLetraMinuscula: false,
          senhaNumero: false,
        },
        acesso: {
          ConfiguracoesSistemaPerfilEditar: false,
        },
      }
    },
    async mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaPerfilEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaPerfilEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaPerfilEditar
      }
      await this.getPoliticaSenhas()
    },
    methods: {
      togglePasswordVisibility() {
        this.campoSenhaAntiga = this.campoSenhaAntiga === 'password' ? 'text' : 'password'
      },
      togglePassword1Visibility() {
        this.campoSenhaNovo = this.campoSenhaNovo === 'password' ? 'text' : 'password'
      },
      togglePassword2Visibility() {
        this.cCampoSenhaNovo = this.cCampoSenhaNovo === 'password' ? 'text' : 'password'
      },
      limpar() {
        this.dados.password = null
        this.senhaNova = null
        this.cSenhaNova = null
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()

        this.$refs.formSenha.validate().then((success) => {
          if (!this.isValidoPoliticaSenha('salvar')) {
            this.$message.error('Erro: senha deve cumprir todos os requisitos')
            return
          }
          if (success) {
            useJwt
              .post(`/auth/verificarSenha`, {
                email: this.optionsLocal.email,
                password: this.dados.password,
              })
              .then(() => {
                useJwt
                  .put(`cadastro/user/updateSenha/${this.optionsLocal.id}`, { password: this.senhaNova })
                  .then(() => {
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Nova senha registrada com sucesso!',
                          icon: 'EditIcon',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-right',
                        timeout: 8000,
                      },
                    )
                    this.limpar()
                  })
                  .catch((error) => {
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Um erro no sistema foi encontrado!',
                          icon: 'EditIcon',
                          variant: 'warning',
                        },
                      },
                      {
                        position: 'top-right',
                        timeout: 8000,
                      },
                    )
                    console.error(error)
                  })
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'A senha atual está incorreta!',
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
                console.error(error)
              })
          }
        })
      },
      async getPoliticaSenhas() {
        useJwt
          .get(`cadastro/instituto/politicaSenhas/${this.optionsLocal.Instituto['id']}`)
          .then((res) => {
            this.politicaSenhas = res.data
          })
          .catch((error) => {
            console.error(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erro: ' + error.response.data.msg,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
          })
      },
      isValidoPoliticaSenha(campo) {
        const politica = this.politicaSenhas
        let regexStr = '^'

        if (this.senhaNova === undefined) {
          return false
        }

        switch (campo) {
          case 'senhaQuantidadeCaractere':
            if (politica.senhaQuantidadeCaractere) {
              regexStr += `.{${politica.senhaQuantidadeCaractere},}$`
            }
            break
          case 'senhaLetraMaiuscula':
            if (politica.senhaLetraMaiuscula) {
              regexStr += '(?=.*[A-Z])'
            }
            break
          case 'senhaLetraMinuscula':
            if (politica.senhaLetraMinuscula) {
              regexStr += '(?=.*[a-z])'
            }
            break
          case 'senhaNumero':
            if (politica.senhaNumero) {
              regexStr += '(?=.*\\d)'
            }
            break
          case 'senhaCaractereEspecial':
            if (politica.senhaCaractereEspecial) {
              regexStr += '(?=.*[!@#\\$%\\^&\\*])'
            }
            break
          default:
            if (politica.senhaQuantidadeCaractere) {
              regexStr += `(?=.{${politica.senhaQuantidadeCaractere},})`
            }
            if (politica.senhaLetraMaiuscula) {
              regexStr += '(?=.*[A-Z])'
            }
            if (politica.senhaLetraMinuscula) {
              regexStr += '(?=.*[a-z])'
            }
            if (politica.senhaNumero) {
              regexStr += '(?=.*\\d)'
            }
            if (politica.senhaCaractereEspecial) {
              regexStr += '(?=.*[!@#\\$%\\^&\\*])'
            }
        }

        const regex = new RegExp(regexStr)
        return regex.test(this.senhaNova)
      },
    },
  }
</script>
